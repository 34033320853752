/**
 * GenericError
 *  Component that sets up a base for creating error pages.
 */

import React, { useRef } from "react";
import "./GenericError.scss";
import acss_logo from "../../assets/img/seal-bg-01.png";
import { StaticCube } from "../StaticCube/StaticCube";

interface Props {
  error_message: string;
  sub_message: string;
}

/**
 * GenericError
 * @param children Children that will be included in the error body.
 * @param error_message The main error message.
 * @param sub_message Comprises the body of the message.
 */
export const GenericError: React.FC<Props> = ({
  children,
  error_message,
  sub_message,
}) => {
  const main_container = useRef<HTMLDivElement>(null);

  return (
    <div className="container">
      <div className="main-container" ref={main_container}>
        <img className="acss-logo" src={acss_logo} alt="ACSS Logo" />
        <div className="canvas">
          <StaticCube base_size={250} />
        </div>
        <div className="messages">
          <h1 className="error-message big-message">{error_message}</h1>
          <div className="error-body">
            <p className="sub-message">{sub_message}</p>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
