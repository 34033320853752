import React from "react";
import { NotFound } from "../components/GenericError/NotFound";
import { Layout } from "../components/Layout";

const _404Page: React.FC = () => {
  return (
    <Layout hasFooter={false}>
      <NotFound />
    </Layout>
  );
};

export default _404Page;
