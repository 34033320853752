import { Link } from "gatsby";
import React from "react";
import { GenericError } from "./GenericError";

export const NotFound: React.FC = () => {
  return (
    <GenericError
      error_message="Are you lost?"
      sub_message="The cube you are looking for isn't this one..."
    >
      <Link className="social-media-link" to="/">
        Go Home
      </Link>
    </GenericError>
  );
};

export default NotFound;
