/**
 * StaticCube.tsx
 *
 * Component for a cube that is displayed starting from the Welcome Section.
 *
 */

import React, { useEffect, useRef } from "react";

import "./StaticCube.scss";
import cube_static from "../../assets/img/cube-static.png";

interface StaticCubeProps {
  current_frame?: number; // The current frame to be used in the image sequence.
  base_size: number;
}

/**
 * StaticCube
 * Component proper.
 */
export const StaticCube: React.FC<StaticCubeProps> = ({
  current_frame,
  base_size,
}) => {
  const canvas_ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    /* Draw a frame to the canvas. */
    const context = canvas_ref.current?.getContext("2d");
    // Values where will we base the rendering of size and effects.
    const canvas_base_size = base_size;
    const shadow_size = 15;
    // Set the size of the canvas.
    if (canvas_ref.current) {
      canvas_ref.current.width = canvas_base_size;
      canvas_ref.current.height = canvas_base_size + shadow_size;
    }
    // Draw the image and apply an effect.
    if (context) {
      const img = new Image();
      img.src = cube_static;
      img.onload = () =>
        window.requestAnimationFrame(() => {
          context.drawImage(
            img,
            0,
            shadow_size / 2,
            canvas_base_size,
            canvas_base_size
          );
        });
      context.shadowColor = "#FFFFFF";
      context.shadowBlur = shadow_size;
    }
  }, [current_frame]);

  return <canvas ref={canvas_ref}></canvas>;
};
